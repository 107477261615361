<template>
  <a-modal
    title="操作"
    style="top: 20px;"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form :form="form">

      <a-form-item style="display:none">
        <a-input v-decorator="['id']"/>
      </a-form-item>

      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="角色名称"
      >
        <a-input
          v-decorator="[
            'name',
            {
              rules: [{ required: true, message: '请输入角色名称' }]
            }
          ]"
          :disabled="true"/>
      </a-form-item>

      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="数据权限"
        extra="特殊情况下，设置为“自定数据权限”"
      >
        <a-select v-decorator="['dataScope', {initialValue:'1'}]" @change="scopeChange">
          <!-- 数据权限范围，0：自定义，1：全部，2：仅自己，3：本部门-->
          <a-select-option :value="0">自定义数据权限</a-select-option>
          <a-select-option :value="1">全部数据权限</a-select-option>
          <a-select-option :value="3">本部门数据权限</a-select-option>
          <a-select-option :value="2" disabled>仅本人数据权限</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="拥有权限"
        v-show="dataScope"
      >
        <div style="display: flex; width: 100%;margin: 20px 0 0 10px">
          <div style="width: 50%">
            <span>部门权限:</span>
            <a-tree
              :checkable="true"
              defaultExpandAll
              :autoExpandParent="autoExpandParent"
              :replace-fields="replaceFields"
              v-if="deptTree.length>0"
              v-model="deptCheckedKeys"
              :treeData="deptTree">
            </a-tree>
          </div>
        </div>
      </a-form-item>
    </a-form>

  </a-modal>
</template>

<script>
  import {authDataScope, dataScopeSelect} from '@/api/system'
  import pick from 'lodash.pick'

  export default {
    name: 'RoleModal',
    data() {
      return {
        labelCol: {
          xs: {span: 24},
          sm: {span: 5}
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 16}
        },
        visible: false,
        confirmLoading: false,
        // 当前角色信息
        mdl: {},

        form: this.$form.createForm(this),
        deptTree: [],
        // 是否显示部门树
        dataScope: false,
        deptCheckedKeys: [],
        replaceFields: {
          children: 'children',
          title: 'name',
          key: 'id'
        },
      }
    },
    methods: {
      add() {
        this.form.resetFields()
        this.edit({})
      },
      edit(record) {
        dataScopeSelect(record.id).then(res => {
          if (res.code === 200) {
            const {roleAreaVO, roleDeptVO} = res.body
            this.deptCheckedKeys = roleDeptVO.checkedKeys || []
            this.deptTree = roleDeptVO.deptTree
          }
        })
        this.mdl = Object.assign({}, record)
        this.dataScope = this.mdl.dataScope === 0
        this.visible = true
        this.$nextTick(() => {
          this.form.setFieldsValue(pick(this.mdl, 'id', 'name', 'dataScope'))
        })
      },
      close() {
        this.$emit('close')
        this.visible = false
      },
      scopeChange(value) {
        this.dataScope = value === 0
      },
      handleOk() {
        const _this = this
        // 触发表单验证
        this.form.validateFields((err, values) => {
          // 验证表单没错误
          if (!err) {
            values.deptIds = this.dataScope ? this.deptCheckedKeys : []
            _this.confirmLoading = true
            authDataScope(Object.assign(values)).then(res => {
              if (res.code === 200) {
                _this.$message.success(res.message)
                _this.$emit('ok')
                _this.visible = false
              } else {
                _this.$message.error(res.message)
              }
            }).catch(() => {
              _this.$message.error('系统错误，请稍后再试')
            }).finally(() => {
              _this.confirmLoading = false
            })
          }
        })
      },
      handleCancel() {
        this.visible = false
      },

    }
  }
</script>

<style scoped>

</style>
